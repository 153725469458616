jQuery(document).ready(function($) {
	$('.toggle-explore-nav-wrapper').on('click', function(event) {
		// If the clicked element is already active, just close it.
		if ( $(this).hasClass('active') ) {
			$(this).children('.toggle-explore-nav-copy').slideToggle();
		} else {
			// Remove the active class from other headlines.
			$('.toggle-explore-nav-wrapper').removeClass('active');
			// Add the active class to the clicked element.
			// $(this).addClass('active');
			// Put the target ID in a variable.
			var targetID = $(this).data("toggle-nav-id");
			// Close all the open things.
			$('.toggle-explore-nav-copy').slideUp();
			// Now open the clicked one.
			$(this).children('.toggle-explore-nav-copy').slideDown();
			// Remove the active class from all the images.
			$('.toggle-explore-image').removeClass('active');
			// And add it to the image with a data id matching the clicked headline.
			$('.toggle-explore-image[data-toggle-image-id="'+ targetID +'"]').addClass('active');
		}
	});
}); /* end of as page load scripts */